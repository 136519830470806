import momemt from 'moment';

jQuery( document ).ready(function($) {

  // mobile menu
  $('.menu-trigger').click(function(e){
    e.preventDefault()
    $(this).toggleClass('active')
    $('.menu, .social').toggleClass('active')
  })

  $('.go-to-section').click(function(){
    $('.menu, .social, .menu-trigger').removeClass('active')
  })
  
  // section picker
  $('.go-to-section').click(function(e){
    e.preventDefault()
    const target = $(this).attr('data-target')
    $('main section').removeClass('active')
    $(`#${target}`).addClass('active')
  })

  // events
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Bishop%20Briggs/events?app_id=45PRESS_BISHOP_BRIGGS',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert( 'Error fetching events!' );
    },
    success: data => {
    const events = $( '#events' );
    let html = '';
    if ( data.length ) {
      for ( let event of data ) {
        let region = event.venue.region?event.venue.region:event.venue.country;
        let location = event.venue.city + ', ' + region;
        if ( location === ', ' ) {location = '';}
        if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}
        html += '<div class="event">';
        html += '<div class="event-side">';
        html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D, Y' ) + '</div>';
        // html += '<div class="event-feat">w/ MisterWives</div>';
        html += '</div>';
        html += '<div class="event-side">';
        html += '<div class="event-venue">' + event.venue.name + '</div>';
        html += '<div class="event-location">' + location + '</div>';
        html += '</div>';
        html += '<div class="event-side">';
        html += '<div class="event-links">';
        for ( let offer of event.offers ) {
          html += '<a href="' + offer.url + '" target="_blank" class="btn btn-outline">' + offer.type + '</a>';
        }
        html += '</div>';
        html += '</div>';
        html += '</div>';
      }
      events.html( html );
      } else {
        events.html( 'No upcoming events.' );
      }
    }
  });

});